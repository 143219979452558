@import '../../../styles/variables.scss';

.info-form-input {
	display: block;
	margin-top: 10px;
	width: 100%;
}

.info-grow {
	display: flex;
	flex-grow: 1;
	.left {
		flex: 1 1 50%;
		margin-right: 5px;
		@media only screen and (max-width: $screen-md-min) {
			flex: 1 1 54%;
		}
	}
	.right {
		flex: 1 1 50%;
		margin-left: 5px;
	}
}

.info-orange-btn {
	margin-top: 60px;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 30px;
	}
}
