@import '../../styles/variables.scss';

.banner {
	margin-top: -100px;
	min-height: 100vh;
	background-size: 100% auto;
	background-position: center bottom;
	background-repeat: no-repeat;

	.slide-content {
		padding: 25vh 0 50px 0;
		max-width: 900px;
		margin: 0 auto;

		h1 {
			margin-bottom: 0.5em;
		}

		p {
			font-family: 'TradeGothicLT';
			padding: 0 3vw;
			margin-bottom: 2em;
		}

		.orange-button {
			margin-bottom: 1.5em;
		}
	}
}
